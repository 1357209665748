import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import {
  BeadMaterialType,
  GrindingTechnology,
  InnerLinerMaterial,
  MACHINE_TYPE_NAMES,
  MachineTypes,
  NewDialog
} from 'web-components';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import Editor from 'components/Editor';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { createNewMachine, editMachineDetails, loadMachine } from 'redux/machines/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedMachine } from 'redux/machines/selectors';
import { generateId, isError, isLoading, isSuccess } from 'helpers/utils';
import { addNotification } from 'redux/ui/notifications/actions';
import { ERROR, SUCCESS } from 'attrs/status';
import { getOrganizationMachinesList } from 'redux/organizations/actions';
import { getBackendStringFormat } from 'helpers/stringHandler';

const FILTERED_OUT_MACHINE_TYPES = [MACHINE_TYPE_NAMES.NETZSCH_PUMP_GENERIC, MACHINE_TYPE_NAMES.UNKNOWN];
const machineTypes = MachineTypes.filter(({ value }) => !FILTERED_OUT_MACHINE_TYPES.includes(value));
const getListWithoutDefaultValue = list => list.filter(data => data.value !== 'UNKNOWN');

const NETZSCH = 'NETZSCH';
const OTHER = 'OTHER';

const zeroToUndefined = value => (value && value > 0 ? +value : undefined);

const CreateEditMachineDetails = ({ open, handleClose, machineDetail, customerIdParam }) => {
  const { t } = useTranslation();
  const { customerId, machineId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: machineResponse, createMachineStatus, editMachineStatus } = useSelector(getSelectedMachine);

  const [manufacturer, setManufacturer] = useState(NETZSCH);
  const [isManufacturerTouched, setManufacturerTouched] = useState(false);
  const handleManufacturerChange = event => {
    const manufacturerParam = event.target.value;
    setManufacturer(manufacturerParam);
    setManufacturerTouched(true);
  };

  const [manufacturerName, setManufacturerName] = useState();
  const [isManufacturerNameTouched, setManufacturerNameTouched] = useState(false);
  const onChangeManufacturerName = event => {
    const manufacturerNameParam = event.target.value;
    setManufacturerName(manufacturerNameParam);
    setManufacturerNameTouched(true);
  };

  const [isSambaChecked, setSambaChecked] = useState(false);
  const [beadSize2, setBeadSize2] = useState();
  const [machineType, setMachineType] = useState();
  const [isMachineTypeTouched, setMachineTypeTouched] = useState(false);
  const handleMachineTypeChange = event => {
    const machineTypeParam = event.target.value;
    setMachineType(machineTypeParam);
    setMachineTypeTouched(true);
    if (machineTypeParam !== 'NETZSCH_MASTER_REFINER') {
      setSambaChecked(false);
      setBeadSize2('');
    }
  };

  const [comissionIdNumber, setComissionIdNumber] = useState();
  const [isComissionIdNumberTouched, setComissionIdNumberTouched] = useState(false);
  const onChangeComissionIdNumber = event => {
    const comissionIdNumberParam = event.target.value;
    setComissionIdNumber(comissionIdNumberParam);
    setComissionIdNumberTouched(true);
  };

  const [name, setName] = useState();
  const [isNameTouched, setNameTouched] = useState(false);
  const onChangeName = event => {
    const nameParam = event.target.value;
    setName(nameParam);
    setNameTouched(true);
  };

  const [productionLine, setProductionLine] = useState();
  const [isProductionLineTouched, setProductionLineTouched] = useState(false);
  const onChangeProductionLine = event => {
    const productionLineParam = event.target.value;
    setProductionLine(productionLineParam);
    setProductionLineTouched(true);
  };

  const [tagId, setTagId] = useState();
  const onChangeTagId = event => {
    const tagIdParam = event.target.value;
    setTagId(getBackendStringFormat(tagIdParam));
  };

  const [technicalInformation, setTechnicalInformation] = useState();
  const onChangeTechnicalInformation = technicalInformationParam => {
    setTechnicalInformation(getBackendStringFormat(technicalInformationParam));
  };

  const onChangeSamba = event => {
    setSambaChecked(event.target.checked);
  };

  const [grindingTechnology, setGrindingTechnology] = useState();
  const onChangeGrindingTechnology = event => {
    const grindingTechnologyParam = event.target.value;
    setGrindingTechnology(getBackendStringFormat(grindingTechnologyParam));
  };

  const [innerMaterial, setInnerMaterial] = useState();
  const onChangeInnerMaterial = event => {
    const innerMaterialParam = event.target.value;
    setInnerMaterial(getBackendStringFormat(innerMaterialParam));
  };

  const [sieveSize, setSieveSize] = useState();
  const onChangeSieveSize = event => {
    setSieveSize(zeroToUndefined(event.target.value));
  };

  const [beadMaterial, setBeadMaterial] = useState();
  const onChangeBeadMaterial = event => {
    const beadMaterialeParam = event.target.value;
    setBeadMaterial(getBackendStringFormat(beadMaterialeParam));
  };

  const [beadSize1, setBeadSize1] = useState();
  const onChangeBeadSize1 = event => {
    setBeadSize1(zeroToUndefined(event.target.value));
  };

  const onChangeBeadSize2 = event => {
    setBeadSize2(zeroToUndefined(event.target.value));
  };

  const [beadFillingLevel, setBeadFillingLevel] = useState();
  const onChangeBeadFillingLevel = event => {
    setBeadFillingLevel(zeroToUndefined(event.target.value));
  };

  useEffect(() => {
    if (machineDetail && Object.keys(machineDetail).length > 0) {
      if (machineDetail.brand === NETZSCH) {
        setManufacturer(machineDetail.brand);
        setMachineType(machineDetail.type);
        setComissionIdNumber(machineDetail.commission_number);
        setName(machineDetail.name);
        setProductionLine(machineDetail.production_line);
        setTagId(machineDetail.tag_id);
        setSambaChecked(machineDetail.mechanical_config?.samba_system);
        setGrindingTechnology(machineDetail.mechanical_config?.grinding_technology);
        setInnerMaterial(machineDetail.mechanical_config?.inner_liner_material);
        setSieveSize(zeroToUndefined(machineDetail.mechanical_config?.sieve_size));
        setBeadMaterial(machineDetail.mechanical_config?.bead_material);
        setBeadSize1(zeroToUndefined(machineDetail.mechanical_config?.beads_size_1));
        setBeadSize2(zeroToUndefined(machineDetail.mechanical_config?.beads_size_2));
        setBeadFillingLevel(zeroToUndefined(machineDetail.mechanical_config?.beads_filling_level));
      } else {
        setManufacturer(machineDetail.brand);
        setManufacturerName(machineDetail.other_brand);
        setMachineType(machineDetail.other_type);
        setComissionIdNumber(machineDetail.id_number);
        setName(machineDetail.name);
        setProductionLine(machineDetail.production_line);
        setTagId(machineDetail.tag_id);
        setTechnicalInformation(machineDetail.other_brand_technical_info);
      }
    } else {
      setManufacturer(NETZSCH);
      setMachineType();
      setComissionIdNumber();
      setName();
      setProductionLine();
      setTagId();
      setSambaChecked(false);
      setGrindingTechnology();
      setInnerMaterial();
      setSieveSize();
      setBeadMaterial();
      setBeadSize1();
      setBeadSize2();
      setBeadFillingLevel();
      setManufacturerName();
      setTechnicalInformation();
    }
    setManufacturerTouched(false);
    setManufacturerNameTouched(false);
    setMachineTypeTouched(false);
    setComissionIdNumberTouched(false);
    setNameTouched(false);
    setProductionLineTouched(false);
  }, [machineDetail, open]);

  const [successCreatingMachine, setSuccessCreatingMachine] = useState(false);
  useEffect(() => {
    if (successCreatingMachine && open) {
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines_bc_yes.add_edit_modal.success_creating'
        })
      );
      setSuccessCreatingMachine(false);
      dispatch(getOrganizationMachinesList(customerIdParam));
      history.push(`/customers/${customerIdParam}/machines/${machineResponse.id}`);
      handleClose();
    }
  }, [successCreatingMachine]);

  const [errorCreatingMachine, setErrorCreatingMachine] = useState(false);
  useEffect(() => {
    if (errorCreatingMachine) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines_bc_yes.add_edit_modal.error_creating'
        })
      );
      setErrorCreatingMachine(false);
    }
  }, [errorCreatingMachine]);

  useEffect(() => {
    if (open) {
      if (isSuccess(createMachineStatus.status)) {
        setSuccessCreatingMachine(true);
      }
      if (isError(createMachineStatus.status)) {
        setErrorCreatingMachine(true);
      }
    }
  }, [createMachineStatus.status]);

  const [successEditingMachine, setSuccessEditingMachine] = useState(false);
  useEffect(() => {
    if (successEditingMachine && open) {
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines_bc_yes.add_edit_modal.success_editing'
        })
      );
      setSuccessEditingMachine(false);
      dispatch(loadMachine(machineId));
      handleClose();
    }
  }, [successEditingMachine]);

  const [errorEditingMachine, setErrorEditingMachine] = useState(false);
  useEffect(() => {
    if (errorEditingMachine) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines_bc_yes.add_edit_modal.error_editing'
        })
      );
      setErrorEditingMachine(false);
    }
  }, [errorEditingMachine]);

  useEffect(() => {
    if (open) {
      if (isSuccess(editMachineStatus.status)) {
        setSuccessEditingMachine(true);
      }
      if (isError(editMachineStatus.status)) {
        setErrorEditingMachine(true);
      }
    }
  }, [editMachineStatus.status]);

  const handleCancel = () => {
    handleClose();
  };

  function canSave() {
    return (
      !!manufacturer &&
      (manufacturer === OTHER ? !!manufacturerName : true) &&
      !!machineType &&
      !!comissionIdNumber &&
      comissionIdNumber.length > 2 &&
      !!name &&
      !!productionLine
    );
  }

  function isEditing() {
    return !!customerId && !!machineId;
  }

  function isSaving() {
    return isLoading(createMachineStatus.status) || isLoading(editMachineStatus.status);
  }

  const handleOk = () => {
    let payload = {};
    if (manufacturer === NETZSCH) {
      payload = {
        machine_details: {
          brand: manufacturer,
          commission_number: comissionIdNumber,
          is_notify: true,
          is_preventive: false,
          mechanical_config: {
            bead_material: beadMaterial,
            beads_filling_level: beadFillingLevel,
            beads_size_1: beadSize1,
            beads_size_2: isSambaChecked ? beadSize2 : undefined,
            grinding_technology: grindingTechnology,
            inner_liner_material: innerMaterial,
            samba_system: isSambaChecked,
            sieve_size: sieveSize
          },
          name,
          production_line: productionLine,
          tag_id: tagId,
          type: machineType
        }
      };
    } else {
      payload = {
        machine_details: {
          brand: manufacturer,
          id_number: comissionIdNumber,
          is_notify: true,
          is_preventive: false,
          name,
          other_brand: manufacturerName,
          other_brand_technical_info: technicalInformation,
          other_type: machineType,
          production_line: productionLine,
          tag_id: tagId
        }
      };
    }
    if (isEditing()) {
      payload.machine_details.id = machineId;
      payload.organization_id = customerId;
      dispatch(editMachineDetails(payload));
    } else {
      payload.organization_id = customerIdParam;
      dispatch(createNewMachine(payload));
    }
  };

  const content = (
    <Box sx={{ width: '978px' }}>
      <Typography sx={{ fontSize: 14, fontWeight: '550', pb: 2 }}>
        {t('machines_bc_yes.add_edit_modal.basic_information')}
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={4}>
          <FormControl variant="filled" required fullWidth error={!manufacturer && isManufacturerTouched}>
            <InputLabel id="manufacturer-label">{t('machines_bc_yes.add_edit_modal.manufacturer')}</InputLabel>
            <Select
              fullWidth
              IconComponent={KeyboardArrowDownIcon}
              labelId="manufacturer-label"
              id="manufacturer-select"
              onChange={handleManufacturerChange}
              value={manufacturer}
            >
              <MenuItem key="OTHER" value="OTHER">
                {t('enum.manufacturer_type.OTHER')}
              </MenuItem>
              <MenuItem key="NETZSCH" value="NETZSCH">
                NETZSCH
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {manufacturer === OTHER ? (
          <>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                id="manufacturer-name"
                label={t('machines_bc_yes.add_edit_modal.manufacturer_name')}
                variant="filled"
                value={manufacturerName}
                onChange={onChangeManufacturerName}
                error={!manufacturerName && isManufacturerNameTouched}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                id="machine-type-text"
                label={t('machines_bc_yes.add_edit_modal.machine_type')}
                variant="filled"
                value={machineType}
                onChange={handleMachineTypeChange}
                error={!machineType && isMachineTypeTouched}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={4}>
            <FormControl variant="filled" required fullWidth error={!machineType && isMachineTypeTouched}>
              <InputLabel id="machine-type-label">{t('machines_bc_yes.add_edit_modal.machine_type')}</InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="machine-type-label"
                id="machine-type-select"
                onChange={handleMachineTypeChange}
                value={machineType}
              >
                {machineTypes.map(mt => (
                  <MenuItem key={mt.value} value={mt.value}>
                    {mt.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            id="name"
            label={
              manufacturer === NETZSCH
                ? t('machines_bc_yes.add_edit_modal.comission_id_number')
                : t('machines_bc_yes.add_edit_modal.id_number')
            }
            variant="filled"
            value={comissionIdNumber}
            onChange={onChangeComissionIdNumber}
            error={(!comissionIdNumber || comissionIdNumber.length < 3) && isComissionIdNumberTouched}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            id="machine-name"
            label={t('machines_bc_yes.add_edit_modal.machine_name')}
            variant="filled"
            value={name}
            onChange={onChangeName}
            error={!name && isNameTouched}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            id="production-line"
            label={t('machines_bc_yes.add_edit_modal.production_line')}
            variant="filled"
            value={productionLine}
            onChange={onChangeProductionLine}
            error={!productionLine && isProductionLineTouched}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="tag-id"
            label={t('machines_bc_yes.add_edit_modal.tag_id')}
            variant="filled"
            value={tagId}
            onChange={onChangeTagId}
          />
        </Grid>
      </Grid>
      <Typography sx={{ fontSize: 14, fontWeight: 'bold', pt: 3, pb: 2 }} gutterBottom>
        {t('machines_bc_yes.add_edit_modal.technical_information')}
      </Typography>
      {manufacturer === OTHER ? (
        <Editor onChange={onChangeTechnicalInformation} />
      ) : (
        <Grid container spacing={2} columns={12}>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                id="samba_system"
                control={
                  <Checkbox
                    checked={isSambaChecked}
                    onChange={onChangeSamba}
                    disabled={machineType !== 'NETZSCH_MASTER_REFINER'}
                  />
                }
                label={t('machines_bc_yes.add_edit_modal.samba_system')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="grinding-tech-place-label">
                {t('machines_bc_yes.add_edit_modal.grinding_technology')}
              </InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="grinding-tech-place-label"
                id="grinding-tech-place-select"
                onChange={onChangeGrindingTechnology}
                value={grindingTechnology}
              >
                <MenuItem value="" sx={{ minHeight: '36px !important' }} />
                {getListWithoutDefaultValue(GrindingTechnology).map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="inner-material-label">{t('machines_bc_yes.add_edit_modal.inner_material')}</InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="inner-material-label"
                id="inner-material-select"
                onChange={onChangeInnerMaterial}
                value={innerMaterial}
              >
                <MenuItem value="" sx={{ minHeight: '36px !important' }} />
                {getListWithoutDefaultValue(InnerLinerMaterial).map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              id="sieve-size"
              label={t('machines_bc_yes.add_edit_modal.sieve_size')}
              variant="filled"
              value={sieveSize}
              onChange={onChangeSieveSize}
              InputProps={{
                inputProps: {
                  min: '0',
                  max: '100000',
                  step: '1'
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="bead-material-label">{t('machines_bc_yes.add_edit_modal.bead_material')}</InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="bead-material-label"
                id="bead-material-select"
                onChange={onChangeBeadMaterial}
                value={beadMaterial}
              >
                <MenuItem value="" sx={{ minHeight: '36px !important' }} />
                {getListWithoutDefaultValue(BeadMaterialType).map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              id="bead-size-1"
              label={t('machines_bc_yes.add_edit_modal.bead_size_1')}
              variant="filled"
              value={beadSize1}
              onChange={onChangeBeadSize1}
              InputProps={{
                inputProps: {
                  min: '0',
                  max: '100000',
                  step: '1'
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              id="bead-size-2"
              label={t('machines_bc_yes.add_edit_modal.bead_size_2')}
              variant="filled"
              value={beadSize2}
              onChange={onChangeBeadSize2}
              InputProps={{
                inputProps: {
                  min: '0',
                  max: '100000',
                  step: '1'
                }
              }}
              disabled={!isSambaChecked}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              id="bead-filling-level"
              label={t('machines_bc_yes.add_edit_modal.bead_filling_level')}
              variant="filled"
              value={beadFillingLevel}
              onChange={onChangeBeadFillingLevel}
              InputProps={{
                inputProps: {
                  min: '0',
                  max: '100',
                  step: '1'
                }
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );

  return (
    <NewDialog
      open={open}
      isSaving={isSaving()}
      canSave={canSave()}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      handleCancel={handleCancel}
      handleOk={handleOk}
      title={
        isEditing() ? t('machines_bc_yes.add_edit_modal.edit_title') : t('machines_bc_yes.add_edit_modal.add_title')
      }
      subTitle={
        isEditing()
          ? t('machines_bc_yes.add_edit_modal.edit_subtitle')
          : t('machines_bc_yes.add_edit_modal.add_subtitle')
      }
      content={content}
      cancelCaption={t('default_actions.cancel')}
      okCaption={t('default_actions.save')}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      maxWidth="98%"
      maxHeight="98%"
    />
  );
};

CreateEditMachineDetails.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  machineDetail: T.instanceOf(Object),
  customerIdParam: T.string
};

CreateEditMachineDetails.defaultProps = {
  machineDetail: null,
  customerIdParam: null
};

export default CreateEditMachineDetails;
