import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import { Chip, FormControl, Input, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { NotifyIcon } from 'web-components';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { CustomCheckbox, getStyles, MenuProps, useStyles } from './styles';

function GatewayFilter({ labelFilter, optionsObj, isCheckbox, clear, setClear, handleFilter, setInitStates }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState(isCheckbox ? [] : '');

  const clearFilter = useCallback(() => {
    setSelectedValue(isCheckbox ? [] : '');
    setInitStates();
    setClear(false);
  }, [setClear, setInitStates, setSelectedValue, isCheckbox]);

  useEffect(() => {
    if (clear) {
      clearFilter();
    }
  }, [clear, clearFilter]);

  useEffect(() => {
    handleFilter(selectedValue);
  }, [handleFilter, selectedValue]);

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };
  const handleDelete = value => {
    const newState = selectedValue.filter(item => item !== value);
    setSelectedValue(newState);
  };

  const IconFilter = () => <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" sx={{ mb: 1 }} />;

  const returnLabel = value => {
    const searchLabel = optionsObj.filter(item => value === item.value);
    return searchLabel[0].label;
  };

  const buildOptionObj = () => {
    if (isCheckbox) {
      return (
        <>
          <InputLabel id="multiple-chip-label" sx={{ color: '#7A7D85', opacity: 0.7 }}>
            {labelFilter}
          </InputLabel>
          <Select
            labelId="multiple-checkbox-label"
            id="multiple-checkbox"
            multiple
            value={selectedValue}
            onChange={handleChange}
            IconComponent={IconFilter}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip
                    key={value}
                    label={returnLabel(value)}
                    clickable
                    deleteIcon={
                      <NotifyIcon fontSize="default" iconName="cancel" onMouseDown={event => event.stopPropagation()} />
                    }
                    className={classes.chip}
                    classes={{ deleteIcon: classes.deleteIcon }}
                    size="small"
                    color="primary"
                    onDelete={() => handleDelete(value)}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {optionsObj.map(item => (
              <MenuItem key={item.value} value={item.value} style={getStyles(item, selectedValue, theme)}>
                <CustomCheckbox checked={selectedValue.indexOf(item.value) > -1} color="primary" />
                {t(item.label)}
              </MenuItem>
            ))}
          </Select>
        </>
      );
    }
    return (
      <>
        <InputLabel id="multiple-chip-label" sx={{ color: '#7A7D85', opacity: 0.7 }}>
          {labelFilter}
        </InputLabel>
        <Select
          labelId="simple-select"
          id="simple-select"
          value={selectedValue}
          onChange={handleChange}
          IconComponent={IconFilter}
          input={<Input id="select-simple" />}
          sx={{ px: 2, py: '1px', color: 'primary' }}
          MenuProps={MenuProps}
        >
          <MenuItem value="" disabled style={{ backgroundColor: 'transparent' }} />
          {optionsObj.map(item => (
            <MenuItem key={item.value} value={item.value} style={getStyles(item, selectedValue, theme)}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  const buildOptionText = () => (
    <>
      <Input
        id="simple-text"
        variant="standard"
        placeholder={labelFilter}
        value={selectedValue}
        onChange={handleChange}
        sx={{ mt: '14px', px: 2, pb: '5px' }}
        endAdornment={
          <InputAdornment position="end">
            <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
          </InputAdornment>
        }
      />
    </>
  );

  return (
    <FormControl className={classes.formControl}>
      {optionsObj.length > 0 ? buildOptionObj() : buildOptionText()}
    </FormControl>
  );
}

GatewayFilter.propTypes = {
  labelFilter: T.string.isRequired,
  optionsObj: T.arrayOf(
    T.shape({
      label: T.string,
      value: T.string
    })
  ),
  isCheckbox: T.bool,
  clear: T.bool,
  setClear: T.func,
  handleFilter: T.func,
  setInitStates: T.func
};

GatewayFilter.defaultProps = {
  isCheckbox: true,
  clear: false,
  optionsObj: [],
  setClear: () => {},
  handleFilter: () => {},
  setInitStates: () => {}
};

export { GatewayFilter };
