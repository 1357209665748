import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { FormElements, NotifyIcon, NotifyTypo, useResolutionCheck } from 'web-components';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { setConfirmationRequest } from 'redux/ui/confirmations/actions';
import {
  StyledBox,
  StyledNewDialog,
  UserEditOverviewColumn,
  UserEditOverviewContent,
  UserEditOverviewRow,
  UserEditStyledHandlers,
  Wrapper
} from './elements';

import { ContentWrapper } from '../../../../elements';
import { StyledContentWrapper } from '../CustomerDetail.styled';
import { getInitials } from '../../../../helpers/utils';
import { StyledTableRow } from '../../../../components/forms/Customers/elements';
import { getOrganizationProductAccess } from '../../../../redux/organizations/selectors';
import { getOrganizationProductsAccessAction } from '../../../../redux/organizations/actions';
import {
  PRODUCT_NOTIFY,
  PRODUCT_PREVENTIVE,
  ROLE_NO_ACCESS,
  getProductNotifyIconConstant
} from '../../../../attrs/products';
import { COLOR_PRIMARY } from '../../../../attrs/colors';
import { RadioLabel } from '../Licenses/elements';
import { userObjectType } from '../../../../components/UserTable/object_types';
import { getUserData } from '../../../../redux/user/selectors';
import { updateUserRolesV2Action } from '../../../../redux/users_v2/actions';

const USER_FIELDS = user => [
  {
    rows: [
      {
        title: 'customers.user_management.list.first_name',
        value: getValueToNullableCell(user?.first_name),
        id: 1
      },
      {
        title: 'customers.user_management.list.last_name',
        value: getValueToNullableCell(user?.last_name),
        id: 2
      },
      { title: 'customers.user_management.list.email', value: getValueToNullableCell(user?.email), id: 3 },
      {
        title: 'customers.user_management.list.phone',
        value: getValueToNullableCell(user?.phone?.phone),
        id: 4
      }
    ],
    id: 1
  }
];

const UserEditDetailsFields = ({ user }) => {
  const { t } = useTranslation();
  const fields = USER_FIELDS(user);

  return fields.map(field => (
    <UserEditOverviewColumn key={`OverviewColumn_${field.id}`}>
      {field.rows.map(({ title, value, id }) => {
        const valueString = typeof value === 'string';
        return (
          <UserEditOverviewRow key={`OverviewRow_${id}`}>
            <NotifyTypo.Subtitle1 sx={{ fontSize: '12px' }}>{t(title)}</NotifyTypo.Subtitle1>
            <Box>{valueString ? <NotifyTypo.Body2 fontSize="14px">{value}</NotifyTypo.Body2> : value}</Box>
          </UserEditOverviewRow>
        );
      })}
    </UserEditOverviewColumn>
  ));
};

const UserEditModal = ({ open, setOpen, user }) => {
  const { isMobile } = useResolutionCheck();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [productsAccess, setProductsAccess] = useState([]);
  const { id: loggedInUserId } = useSelector(getUserData);

  const [initialValues, setInitialValues] = useState({
    user_id: '',
    updated_by_user_id: loggedInUserId,
    notify_role: '',
    preventive_role: ''
  });

  useEffect(() => {
    if (open) {
      dispatch(getOrganizationProductsAccessAction(user.organization_id));

      let roleNotify = '';
      let rolePreventive = '';
      user.roles.forEach(role => {
        switch (role.product) {
          case PRODUCT_NOTIFY:
            roleNotify = role.role;
            break;
          case PRODUCT_PREVENTIVE:
            rolePreventive = role.role;
            break;
          default:
        }
      });

      setInitialValues({
        user_id: user.id,
        updated_by_user_id: loggedInUserId,
        notify_role: roleNotify,
        preventive_role: rolePreventive
      });
    }
  }, [dispatch, open, user, loggedInUserId]);

  const { products_access: selectorProductsAccess } = useSelector(getOrganizationProductAccess);
  useEffect(() => {
    setProductsAccess(selectorProductsAccess);
  }, [setProductsAccess, selectorProductsAccess]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleFormSubmit = values => {
    const roles = [];
    if (values.notify_role !== '') {
      roles.push({
        role: values.notify_role,
        product: PRODUCT_NOTIFY
      });
    }
    if (values.preventive_role !== '') {
      roles.push({
        role: values.preventive_role,
        product: PRODUCT_PREVENTIVE
      });
    }

    const updateUserRolesV2Request = {
      user_id: values.user_id,
      updated_by_user_id: values.updated_by_user_id,
      roles
    };

    dispatch(updateUserRolesV2Action(updateUserRolesV2Request));
  };

  const handleFormCancel = () => {
    dispatch(
      setConfirmationRequest({
        message: 'dialog.confirmation.form_unsaved_changes',
        action: () => {
          handleCloseModal();
        }
      })
    );
  };

  const userEditModalActionBar = handleSubmit => (
    <UserEditStyledHandlers>
      <Button color="secondary" onClick={handleFormCancel}>
        {t('customers.form.cancel')}
      </Button>
      <Button
        disabled={false}
        variant="contained"
        style={{ padding: '1rem 2.5rem' }}
        color="primary"
        onClick={async () => {
          handleCloseModal();
          await handleSubmit();
        }}
        type="submit"
      >
        {t('form.save')}
      </Button>
    </UserEditStyledHandlers>
  );

  return (
    <Formik initialValues={initialValues} onSubmit={values => handleFormSubmit(values)} enableReinitialize>
      {({ handleSubmit }) => (
        <StyledNewDialog
          open={open}
          maxWidth="736px"
          title="Products"
          subTitle="Manage the user’s product roles grant product access"
          displayActionBar="true"
          actionBar={userEditModalActionBar(handleSubmit)}
          handleClose={handleCloseModal}
          content={
            <StyledBox>
              <Wrapper type="content-wrapper" style={{ width: '100%', paddingLeft: '0px' }} theme={theme}>
                <ContentWrapper
                  style={{ marginBottom: !isMobile && '4rem' }}
                  maxWidth={false}
                  data-selector="customer-details"
                >
                  <StyledContentWrapper elevation={3}>
                    <Card sx={{ p: 4 }}>
                      <CardHeader
                        sx={{ p: 0 }}
                        title="User Information"
                        titleTypographyProps={{
                          sx: {
                            fontWeight: 'bold',
                            fontSize: `1rem`
                          },
                          variant: 'Body1'
                        }}
                      />
                      <UserEditOverviewContent>
                        <Avatar
                          key={`item-avatar-${user?.id}`}
                          alt={`${user?.first_name} ${user?.last_name}`}
                          src={user?.photo}
                          sx={{
                            fontSize: 20,
                            width: 80,
                            height: 80,
                            boxShadow: 3,
                            ...(!user?.photo && {
                              color: '#58BAAA',
                              bgcolor: '#CEEAE5',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              borderColor: '#DDDDDD80'
                            })
                          }}
                        >
                          {!user?.photo && getInitials(`${user?.first_name} ${user?.last_name}`)}
                        </Avatar>
                        <UserEditDetailsFields user={user} />
                      </UserEditOverviewContent>
                    </Card>
                  </StyledContentWrapper>
                  <Table sx={{ minWidth: 650, marginTop: '2rem' }} size="small" aria-label="a dense table">
                    <TableHead>
                      <StyledTableRow>
                        <TableCell sx={{ color: 'rgba(122, 125, 133, 1)' }}>{t('products.products')}</TableCell>
                        <TableCell sx={{ color: 'rgba(122, 125, 133, 1)' }}>{t('products.products_roles')}</TableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <UserProductAccessTableRows
                        productsAccess={productsAccess}
                        patternValues={initialValues}
                        isOpen={open}
                      />
                    </TableBody>
                  </Table>
                </ContentWrapper>
              </Wrapper>
            </StyledBox>
          }
        />
      )}
    </Formik>
  );
};

const UserProductAccessTableRows = ({ productsAccess, patternValues, isOpen }) => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext();

  useEffect(() => {
    if (isOpen) {
      setValues(patternValues);
    }
  }, [isOpen]);

  const handleRadioDisabled = (role, product) => {
    if (values.role_notify === ROLE_NO_ACCESS && product === PRODUCT_PREVENTIVE && role === ROLE_NO_ACCESS) {
      return true;
    }
    return values.role_preventive === ROLE_NO_ACCESS && product === PRODUCT_NOTIFY && role === ROLE_NO_ACCESS;
  };

  const handleRadioChange = ({ target: { name, value } }) => {
    setValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (Array.isArray(productsAccess)) {
    return productsAccess.map(productAccess => (
      <TableRow
        id={`edit_user_row_product_access_${productAccess.product}`}
        key={`edit_user_row_product_access_${productAccess.product}`}
      >
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', alignContent: 'center' }}>
            <span style={{ display: 'flex', gap: '0.5rem' }}>
              <NotifyIcon
                fontSize="large"
                iconName={getProductNotifyIconConstant(productAccess.product)}
                style={{ color: '#2BACA4', fontSize: '24px' }}
              />
              {t(`products.${productAccess.product}`)}
            </span>
          </div>
        </TableCell>
        <TableCell>
          <RadioGroup
            row
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            name={`${productAccess.product}_role`}
            id={`${productAccess.product}_role`}
          >
            {Array.isArray(productAccess.roles) ? (
              productAccess.roles.map(role => (
                <RadioLabel key={role}>
                  <FormElements.RadioButton
                    margin="normal"
                    activeColor={COLOR_PRIMARY}
                    value={role}
                    id={`${productAccess.product}_role`}
                    name={`${productAccess.product}_role`}
                    onChange={handleRadioChange}
                    disabled={handleRadioDisabled(role, productAccess.product)}
                    checked={values[`${productAccess.product}_role`] === role}
                  />
                  <span>{t(`products.roles.${role}`)}</span>
                </RadioLabel>
              ))
            ) : (
              <RadioLabel key="no_access">
                <FormElements.RadioButton
                  margin="normal"
                  activeColor={COLOR_PRIMARY}
                  id={`${productAccess.product}_role`}
                  name={`${productAccess.product}_role`}
                  disabled
                />
                <span>{t(`products.roles.no_access`)}</span>
              </RadioLabel>
            )}
          </RadioGroup>
        </TableCell>
      </TableRow>
    ));
  }
  return <></>;
};

UserProductAccessTableRows.propTypes = {
  productsAccess: T.arrayOf(T.shape({})),
  patternValues: T.arrayOf(T.shape({})),
  isOpen: T.bool
};

UserProductAccessTableRows.defaultProps = {
  productsAccess: [],
  patternValues: [],
  isOpen: false
};

UserEditModal.propTypes = {
  open: T.bool.isRequired,
  setOpen: T.func.isRequired,
  user: userObjectType.isRequired
};

export default UserEditModal;
