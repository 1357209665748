import React, { useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { NewDialog } from 'web-components';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSensorReducerState } from 'redux/rootSelectors';
import { generateId, isError, isLoading, isSuccess, validateBlankSpace } from 'helpers/utils';
import { addNotification } from 'redux/ui/notifications/actions';
import { ERROR, SUCCESS } from 'attrs/status';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CUSTOM_ICON_TYPE_LIST, DECIMAL_PLACES, PERIODS_LIST } from '../../../../../attrs/sensorConfig';
import { createSensor, loadSensor, updateSensor } from '../../../../../redux/sensor/actions';

const AddStaticSensor = ({ open, handleClose, machineId, data }) => {
  const { creatingSensorStatus, updatingSensorStatus } = useSelector(getSensorReducerState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [isNameTouched, setNameTouched] = useState(false);
  const onChangeName = event => {
    const nameParam = event.target.value;
    setName(nameParam);
    setNameTouched(true);
  };

  const [source, setSource] = useState('');
  const [isSourceTouched, setSourceTouched] = useState(false);
  const onChangeSource = event => {
    let sourceParam = event.target.value;
    sourceParam = validateBlankSpace(sourceParam);
    setSource(sourceParam);
    setSourceTouched(true);
  };

  const [sensorType, setSensorType] = useState('');
  const [isSensorTypeTouched, setSensorTypeTouched] = useState(false);
  const handleSensorTypeChange = event => {
    const sensorTypeParam = event.target.value;
    setSensorType(sensorTypeParam);
    setSensorTypeTouched(true);
  };

  const [sensorPeriod, setSensorPeriod] = useState('');
  const [isSensorPeriodTouched, setSensorPeriodTouched] = useState(false);
  const handleSensorPeriodChange = event => {
    let sensorPeriodParam = event.target.value;
    sensorPeriodParam = validateBlankSpace(sensorPeriodParam);
    setSensorPeriod(sensorPeriodParam);
    setSourceTouched(true);
  };

  const [unit, setUnit] = useState('');
  const [isUnitTouched, setUnitTouched] = useState(false);
  const onChangeUnit = event => {
    const unitParam = event.target.value;
    setUnit(unitParam);
    setUnitTouched(true);
  };

  const [valueType, setValueType] = useState('');
  const onChangeValueType = event => {
    const valueTypeParam = event.target.value;
    setValueType(valueTypeParam);
  };

  const [multiplicationFactor, setMultiplicationFactor] = useState(0);
  const [isMultiplicationFactorTouched, setMultiplicationFactorTouched] = useState(false);
  const onChangeMultiplicationFactor = event => {
    const multiplicationFactorParam = +event.target.value;
    setMultiplicationFactor(multiplicationFactorParam);
    setMultiplicationFactorTouched(true);
  };

  const [decimalPlace, setDecimalPlace] = useState('0');
  const [isDecimalPlaceTouched, setDecimalPlaceTouched] = useState(false);
  const onChangeDecimalPlace = event => {
    const decimalPlaceParam = event.target.value;
    setDecimalPlace(decimalPlaceParam);
    setDecimalPlaceTouched(true);
  };

  const [staticSensorId, setAddStaticSensorId] = useState();
  useEffect(() => {
    if (data && data.static_sensor_id) {
      setAddStaticSensorId(data.static_sensor_id);
      setName(data.name);
      setSensorPeriod(data.custom_period);
      setSensorType(data.custom_icon_type);
      setUnit(data.custom_unit);
      setSource(data.source);
      setValueType(data.value_type);
      setMultiplicationFactor(data.multiplication_factor);
      setDecimalPlace(data.decimal_place);
    } else {
      setAddStaticSensorId(undefined);
      setName('');
      setNameTouched(false);
      setSensorPeriod('');
      setSensorPeriodTouched(false);
      setSensorType('');
      setSensorTypeTouched(false);
      setUnit('');
      setUnitTouched(false);
      setSource('');
      setSourceTouched(false);
      setValueType('');
      setMultiplicationFactor(0);
      setDecimalPlace('0');
    }
  }, [data, open]);

  const [successCreatingSensor, setSuccessCreatingSensor] = useState(false);
  useEffect(() => {
    if (successCreatingSensor && open) {
      dispatch(loadSensor(machineId));
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines.form.sensor.message.creating_success'
        })
      );
      setSuccessCreatingSensor(false);
      handleClose();
    }
  }, [successCreatingSensor, dispatch, handleClose, machineId, open]);

  const [errorCreatingSensor, setErrorCreatingSensor] = useState(false);
  useEffect(() => {
    if (errorCreatingSensor) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines.form.sensor.message.creating_error'
        })
      );
      setErrorCreatingSensor(false);
    }
  }, [errorCreatingSensor, dispatch]);

  useEffect(() => {
    if (open) {
      if (isSuccess(creatingSensorStatus.status)) {
        setSuccessCreatingSensor(true);
      }
      if (isError(creatingSensorStatus.status)) {
        setErrorCreatingSensor(true);
      }
    }
  }, [creatingSensorStatus.status, open]);

  const [successUpdatingSensor, setSuccessUpdatingSensor] = useState(false);
  useEffect(() => {
    if (successUpdatingSensor && open) {
      dispatch(loadSensor(machineId));
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines.form.sensor.message.updating_success'
        })
      );
      setSuccessUpdatingSensor(false);
      handleClose();
    }
  }, [successUpdatingSensor, dispatch, handleClose, machineId, open]);

  const [errorUpdatingSensor, setErrorUpdatingSensor] = useState(false);
  useEffect(() => {
    if (errorUpdatingSensor) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines.form.sensor.message.updating_error'
        })
      );
      setErrorUpdatingSensor(false);
    }
  }, [errorUpdatingSensor, dispatch]);

  useEffect(() => {
    if (open) {
      if (isSuccess(updatingSensorStatus.status)) {
        setSuccessUpdatingSensor(true);
      }
      if (isError(updatingSensorStatus.status)) {
        setErrorUpdatingSensor(true);
      }
    }
  }, [updatingSensorStatus.status, open]);

  const handleCancel = () => {
    handleClose();
  };

  function canSave() {
    return !!name && !!sensorPeriod && !!sensorType && !!unit && !!source && !!valueType;
  }

  function isSaving() {
    return isLoading(creatingSensorStatus.status) || isLoading(updatingSensorStatus.status);
  }

  const handleOk = () => {
    const isEditing = !!staticSensorId || false;
    const payload = {
      is_custom: false,
      is_static: true,
      name,
      period: '',
      custom_period: sensorPeriod,
      custom_icon_type: sensorType,
      custom_unit: unit,
      decimal_place: decimalPlace,
      multiplication_factor: multiplicationFactor,
      source,
      type: '',
      units: '',
      value_type: valueType
    };

    if (isEditing) {
      payload.static_sensor_id = staticSensorId;
      dispatch(updateSensor(machineId, payload));
    } else {
      dispatch(createSensor(machineId, payload));
    }
  };

  const content = (
    <Grid container spacing={2} columns={12} sx={{ maxWidth: '1062px' }}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="name"
          label={t('machines.form.sensor.name')}
          variant="filled"
          value={name}
          onChange={onChangeName}
          error={!name && isNameTouched}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="source"
          label={t('machines.form.sensor.plc_source')}
          variant="filled"
          value={source}
          onChange={onChangeSource}
          error={!source && isSourceTouched}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="filled" required fullWidth error={!sensorType && isSensorTypeTouched}>
          <InputLabel id="sensor-type">{t('machines.form.sensor.custom_icon_type')}</InputLabel>
          <Select
            fullWidth
            IconComponent={KeyboardArrowDownIcon}
            labelId="sensor-type-label"
            id="sensor-type-select"
            onChange={handleSensorTypeChange}
            value={sensorType}
          >
            {CUSTOM_ICON_TYPE_LIST.map(iconType => (
              <MenuItem key={iconType.c} value={iconType.c}>
                {t(`${iconType.t}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="filled" required fullWidth error={!sensorPeriod && isSensorPeriodTouched}>
          <InputLabel id="sensor-period">{t('machines.form.sensor.custom_period')}</InputLabel>
          <Select
            fullWidth
            IconComponent={KeyboardArrowDownIcon}
            labelId="sensor-period-label"
            id="sensor-period-select"
            onChange={handleSensorPeriodChange}
            value={sensorPeriod}
          >
            {PERIODS_LIST.map(period => (
              <MenuItem key={period} value={period}>
                {t(`machines.form.sensor.periods.${period}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          id="unit"
          label={t('machines.form.sensor.custom_unit')}
          variant="filled"
          value={unit}
          onChange={onChangeUnit}
          error={!unit && isUnitTouched}
        />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          row
          aria-labelledby="radio-value-type-label"
          name="radio-value-type"
          value={valueType}
          onChange={onChangeValueType}
        >
          <FormControlLabel value="STRING" control={<Radio />} label={t('machines.form.sensor.text_radio')} />
          <FormControlLabel value="NUMBER" control={<Radio />} label={t('machines.form.sensor.numeric_radio')} />
          <FormControlLabel value="BOOLEAN" control={<Radio />} label={t('machines.form.sensor.boolean_radio')} />
        </RadioGroup>
      </Grid>
      {valueType === 'NUMBER' && (
        <>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="number"
              id="multiplication-factor"
              label={t('machines.form.sensor.multiplication_factor')}
              variant="filled"
              value={multiplicationFactor}
              onChange={onChangeMultiplicationFactor}
              error={!multiplicationFactor && isMultiplicationFactorTouched}
              InputProps={{
                inputProps: {
                  min: '0',
                  max: '100000',
                  step: '1'
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="filled" required fullWidth error={!decimalPlace && isDecimalPlaceTouched}>
              <InputLabel id="decimal-place">{t('machines.form.sensor.decimal_place')}</InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="decimal-place-label"
                id="decimal-place-select"
                onChange={onChangeDecimalPlace}
                value={decimalPlace}
              >
                {DECIMAL_PLACES.map(num => (
                  <MenuItem key={`${num.toString()}`} value={`${num.toString()}`}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <NewDialog
      open={open}
      canSave={canSave()}
      isSaving={isSaving()}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      handleCancel={handleCancel}
      handleOk={handleOk}
      title={
        data === null
          ? t('machines.machine_details.sensor_static_modal.title')
          : t('machines.machine_details.sensor_static_modal.title_edit')
      }
      subTitle={
        data === null
          ? t('machines.machine_details.sensor_static_modal.subtitle')
          : t('machines.machine_details.sensor_static_modal.subtitle_edit')
      }
      content={content}
      cancelCaption={t('default_actions.cancel')}
      okCaption={t('default_actions.save')}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      maxWidth="98%"
      maxHeight="98%"
    />
  );
};

AddStaticSensor.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  machineId: string.isRequired,
  data: shape
};

AddStaticSensor.defaultProps = {
  data: null
};

export default AddStaticSensor;
