import { Box, Card, Divider, IconButton, Stack } from '@mui/material';
import T from 'prop-types';
import React, { useState } from 'react';
import { FormElements, MachineIcon, NotifyIcon, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import { getSensorProps } from 'helpers/machine';
import { useDispatch } from 'react-redux';
import { updateSensorStatus } from 'redux/machines/actions';
import DeleteSensor from '../DeleteSensorModal/DeleteSensorModal';
import AddStaticSensor from '../AddStaticSensorModal/AddStaticSensorModal';

const SensorMap = ({ sensors, machineId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteSensor, setDeleteSensor] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [listStaticSensorIsActive, setListStaticSensorIsActive] = useState(
    sensors?.sensors.static?.map(item => item.is_active)
  );
  let idString = '';

  if (selectedItemId !== null) {
    idString = `static_sensor_id_${selectedItemId.toString()}`;
  }

  const handleCloseDelete = () => setDeleteSensor(false);
  const handleCloseEdit = () => setEditModal(false);

  const handleEditData = itemId => {
    setEditModal(true);
    setSelectedValue(itemId);
  };

  const handleDeleteIconClick = itemId => {
    setDeleteSensor(true);
    setSelectedItemId(itemId);
  };

  const handleActiveStaticSensor = (itemParam, idxParam) => {
    setListStaticSensorIsActive(
      listStaticSensorIsActive.map((value, idx) => {
        if (idxParam === idx) return !value;
        return value;
      })
    );
    const payload = {
      machine_id: machineId,
      static_sensor_id: itemParam.static_sensor_id
    };
    dispatch(updateSensorStatus(machineId, payload));
  };

  function isStaticSensorActive(idx) {
    if (listStaticSensorIsActive.length > 0) return listStaticSensorIsActive[idx];
    return false;
  }

  return (
    <Box>
      {sensors?.sensors.static?.map((item, idx) => (
        <Card
          sx={{ px: 4, py: 2, my: 2, display: 'flex', justifyContent: 'space-between' }}
          key={item.static_sensor_id}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MachineIcon
              status="OK"
              iconName={getSensorProps(item.custom_icon_type).icon}
              viewBox="0 0 25 25"
              fontSize="large"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%'
              }}
            />
            <NotifyTypo.Heading1 fontWeight="bold" sx={{ mx: 1 }}>
              {item.name}
            </NotifyTypo.Heading1>
            <NotifyTypo.Subtitle1 sx={{ mx: 1 }}>
              {t('machines.form.static_values.address')}: {item.source || '---'}
            </NotifyTypo.Subtitle1>
            <NotifyTypo.Subtitle1 sx={{ mx: 1 }}>
              {t('machines.form.static_values.unit')}: {item.custom_unit || '---'}
            </NotifyTypo.Subtitle1>
            <NotifyTypo.Subtitle1 sx={{ ml: 1 }}>
              {t('machines.form.static_values.period')}: {item.custom_period || '---'}
            </NotifyTypo.Subtitle1>
          </Box>
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => handleEditData(item)}>
              <NotifyIcon color="primary" fontSize="large" iconName="edit" />
            </IconButton>
            <IconButton onClick={() => handleDeleteIconClick(item.static_sensor_id)}>
              <NotifyIcon color="primary" fontSize="large" iconName="delete" />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <FormElements.ToggleButton
              label={isStaticSensorActive(idx) ? t('machines.form.sensor.active') : t('machines.form.sensor.inactive')}
              name={`activeStaticSensor_${idx}`}
              id={`activeStaticSensor_${idx}`}
              size="large"
              margin="none"
              checked={isStaticSensorActive(idx)}
              onChange={() => handleActiveStaticSensor(item, idx)}
              disabled={false}
            />
          </Stack>
        </Card>
      ))}

      <AddStaticSensor handleClose={handleCloseEdit} open={editModal} machineId={machineId} data={selectedValue} />
      <DeleteSensor handleClose={handleCloseDelete} open={deleteSensor} sensorType={idString} machineId={machineId} />
    </Box>
  );
};

SensorMap.propTypes = {
  sensors: T.shape({
    sensors: T.instanceOf(Object)
  }),
  machineId: T.string
};

SensorMap.defaultProps = {
  sensors: null,
  machineId: null
};

export default SensorMap;
