import { Box, Card, Divider, IconButton, Stack } from '@mui/material';
import T from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormElements, MachineIcon, NotifyIcon, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import { getSensorProps } from 'helpers/machine';
import { useDispatch } from 'react-redux';
import { updateSensorStatusIrisV3 } from 'redux/machines_v2/actions';
import { convertSecondsToTime } from 'helpers/utils';
import DeleteSensor from '../DeleteSensorModal/DeleteSensorModal';
import AddStaticSensorIrisV3 from '../AddStaticSensorIrisV3Modal/AddStaticSensorIrisV3Modal';
import { PlcType } from '../../../../../attrs/plcConfigValues';

const SensorMapIrisV3 = ({ sensors, machineId, plcType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteSensor, setDeleteSensor] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const initialSensorState =
    plcType.plc_type === PlcType.IRIS_V3
      ? (Array.isArray(sensors?.sensors?.iris) ? sensors.sensors.iris : []).map(item => item.is_active)
      : (Array.isArray(sensors?.sensors?.static) ? sensors.sensors.static : []).map(item => item.is_active);

  const [listStaticSensorIsActive, setListStaticSensorIsActive] = useState(initialSensorState);

  useEffect(() => {
    setListStaticSensorIsActive(initialSensorState);
  }, [sensors, plcType]);

  let idString = '';

  if (selectedItemId !== null) {
    idString = `static_sensor_id_${selectedItemId.toString()}`;
  }

  const handleCloseDelete = () => setDeleteSensor(false);
  const handleCloseEdit = () => setEditModal(false);

  const handleEditData = itemId => {
    setEditModal(true);
    setSelectedValue(itemId);
  };

  const handleDeleteIconClick = itemId => {
    setDeleteSensor(true);
    setSelectedItemId(itemId);
  };

  const handleActiveStaticSensor = (itemParam, idxParam) => {
    setListStaticSensorIsActive(
      listStaticSensorIsActive.map((value, idx) => {
        if (idxParam === idx) return !value;
        return value;
      })
    );

    const { id: staticSensor } = itemParam;

    dispatch(updateSensorStatusIrisV3(machineId, staticSensor));
  };

  function isStaticSensorActive(idx) {
    if (listStaticSensorIsActive.length > 0) return listStaticSensorIsActive[idx];
    return false;
  }

  const renderSensors = sensorsList =>
    sensorsList.map((item, idx) => (
      <Card sx={{ px: 4, py: 2, my: 2, display: 'flex', justifyContent: 'space-between' }} key={item.id}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MachineIcon
            status="OK"
            iconName={getSensorProps(item.icon_type || item.type).icon}
            viewBox="0 0 27 27"
            fontSize="large"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%'
            }}
          />
          <NotifyTypo.Heading1 fontWeight="bold" sx={{ mx: 1 }}>
            {item.name}
          </NotifyTypo.Heading1>
          <NotifyTypo.Subtitle1 sx={{ mx: 1 }}>
            {t('machines.form.static_values.variable_name')}: {item.variable_name || '---'}
          </NotifyTypo.Subtitle1>
          <NotifyTypo.Subtitle1 sx={{ mx: 1 }}>
            {t('machines.form.static_values.unit')}: {item.unit || '---'}
          </NotifyTypo.Subtitle1>
          <NotifyTypo.Subtitle1 sx={{ ml: 1 }}>
            {t('machines.form.static_values.period')}: {convertSecondsToTime(item.interval) || '---'}
          </NotifyTypo.Subtitle1>
        </Box>
        <Stack direction="row" spacing={1}>
          <IconButton onClick={() => handleEditData(item)}>
            <NotifyIcon color="primary" fontSize="large" iconName="edit" />
          </IconButton>
          <IconButton onClick={() => handleDeleteIconClick(item.static_sensor_id || item.id)}>
            <NotifyIcon color="primary" fontSize="large" iconName="delete" />
          </IconButton>
          <Divider orientation="vertical" flexItem />
          <FormElements.ToggleButton
            label={isStaticSensorActive(idx) ? t('machines.form.sensor.active') : t('machines.form.sensor.inactive')}
            name={`activeStaticSensor_${idx}`}
            id={`activeStaticSensor_${idx}`}
            size="large"
            margin="none"
            checked={isStaticSensorActive(idx)}
            onChange={() => handleActiveStaticSensor(item, idx)}
            disabled={false}
          />
        </Stack>
      </Card>
    ));

  return (
    <Box>
      {plcType.plc_type === PlcType.IRIS_V3 &&
        renderSensors(Array.isArray(sensors?.sensors?.iris) ? sensors.sensors.iris : [])}

      <AddStaticSensorIrisV3
        handleClose={handleCloseEdit}
        open={editModal}
        machineId={machineId}
        data={selectedValue}
        plcType={plcType}
      />
      <DeleteSensor
        handleClose={handleCloseDelete}
        open={deleteSensor}
        sensorType={idString}
        machineId={machineId}
        plcType={plcType}
        selectedItemId={selectedItemId}
      />
    </Box>
  );
};

SensorMapIrisV3.propTypes = {
  sensors: T.shape({
    sensors: T.instanceOf(Object)
  }),
  machineId: T.string,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

SensorMapIrisV3.defaultProps = {
  sensors: null,
  machineId: null
};

export default SensorMapIrisV3;
